import React from "react"
import {StoryGroup} from "components";

const LinkMap = {
	'Sketch': '394338279e0f',
	'Wireframe': '394338279e0f',
	'Mockup': '394338279e0f',
	'Prototype': '394338279e0f',
	'UIX': '394338279e0f',
}
const stories = [
	{
		title: "Sketch, Wireframe, Mockup, Prototype arasındaki fark?",
		postId: "394338279e0f",
	},
]

export const context = {
	stories: stories,
	linkMap: LinkMap,
	title: "UIX Design",
	path: 'uix-design',
}



const UIXDesignPage = () => {

	return (
		<StoryGroup
			title={context.title}
			stories={context.stories}
			linkMap={context.linkMap}
		/>
	)
}

export default UIXDesignPage



